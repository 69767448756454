export default [
  {
    name: 'users',
    icon: 'mdi-account-multiple',
    styleName: 'users',
    isToolWorking: true,
    isAdmin: true,
  },
  {
    name: 'dictionaries',
    icon: 'mdi-book-alphabet',
    styleName: 'dictionaries',
    isToolWorking: true,
    isAdmin: true,
  },
  {
    name: 'layers',
    icon: 'mdi-layers',
    styleName: 'layers',
    isToolWorking: true,
    isAdmin: true,
  },
  {
    name: 'dataSources',
    icon: 'mdi-database',
    styleName: 'dataSources',
    isToolWorking: true,
    isAdmin: true,
  },
  {
    name: 'additionalModules',
    styleName: 'additionalModules',
    icon: 'mdi-apps',
    isList: true,
    items: [
      {
        name: 'mobileApp',
        styleName: 'mobileApp',
        enableModuleName: ['AQUAGIS_MOBILE'],
        isToolWorking: true,
        isAdmin: true,
        isAuthorized: true,
      },
      {
        name: 'mobileAppOffline',
        styleName: 'mobileAppOffline',
        isToolWorking: true,
        isAdmin: true,
        enableModuleName: ['AQUAGIS_OFFLINE_MOBILE'],
        isAuthorized: true,
      },
      {
        name: 'mobileAppZDM',
        styleName: 'mobileAppZDM',
        isToolWorking: true,
        enableModuleName: ['ZDM_MOBILE'],
        isAdmin: true,
        isAuthorized: true,
      },
      {
        name: 'zdmFailureReportAdmin',
        styleName: 'zdmFailureReportAdmin',
        isToolWorking: true,
        enableModuleName: ['ZDM_FAILURES_REPORT'],
        isAdmin: true,
        isAuthorized: true,
      },
      {
        name: 'zdmFailureStatusAdmin',
        styleName: 'zdmFailureStatusAdmin',
        isToolWorking: true,
        enableModuleName: ['ZDM_FAILURES_STATUS'],
        isAdmin: true,
        isAuthorized: true,
      },
      {
        name: 'zdmStreetLights',
        styleName: 'zdmStreetLights',
        isToolWorking: true,
        isAdmin: true,
        enableModuleName: ['ZDM_DATA'],
        isAuthorized: true,
      },
      {
        name: 'zdmFailuresAdmin',
        styleName: 'zdmFailuresAdmin',
        isToolWorking: true,
        isAdmin: true,
        enableModuleName: ['ZDM_FAILURES'],
        isAuthorized: true,
      },
      {
        name: 'zdmInspectors',
        styleName: 'zdmInspectors',
        isToolWorking: true,
        isAdmin: true,
        enableModuleName: ['ZDM_INSPECTORS'],
        isAuthorized: true,
      },
      {
        name: 'zdmReports',
        styleName: 'zdmReports',
        isToolWorking: true,
        isAdmin: true,
        enableModuleName: ['ZDM_REPORTS'],
        isAuthorized: true,
      },
      {
        name: 'automaticDigitizationSettings',
        styleName: 'automaticDigitizationSettings',
        isToolWorking: true,
        isAdmin: true,
        enableModuleName: ['AUTOMATIC_DIGITIZATION'],
        isAuthorized: true,
      },
      {
        name: 'notifications',
        styleName: 'notifications',
        isToolWorking: true,
        isAdmin: true,
        enableModuleName: ['NOTIFICATIONS_MODULE'],
        isAuthorized: true,
      },
      {
        name: 'zdmEditorSettings',
        styleName: 'zdmEditorSettings',
        isToolWorking: true,
        isAdmin: true,
        enableModuleName: ['ZDM_SESSIONS'],
        isAuthorized: true,
      },
      {
        name: 'zdmLightingControl',
        styleName: 'zdmLightingControl',
        isToolWorking: true,
        isAdmin: true,
        enableModuleName: ['LIGHTING_CONTROL'],
        isAuthorized: true,
      },
      {
        name: 'FAILURES',
        styleName: 'FAILURES',
        isToolWorking: true,
        isAdmin: true,
        enableModuleName: ['FAILURES'],
        isAuthorized: true,
      },
      {
        name: 'FAILURES2',
        styleName: 'FAILURES2',
        isToolWorking: true,
        isAdmin: true,
        enableModuleName: ['FAILURES_2'],
        isAuthorized: true,
      },
      {
        name: 'ILS',
        styleName: 'ILS',
        isToolWorking: true,
        isAdmin: true,
        enableModuleName: ['ILS_MODULE'],
        isAuthorized: true,
      },
      {
        name: 'SCADA',
        styleName: 'SCADA',
        isToolWorking: true,
        isAdmin: true,
        enableModuleName: ['SCADA'],
        isAuthorized: true,
      },
      {
        name: 'MASTERPLAN_STREETS',
        styleName: 'MASTERPLAN_STREETS',
        isToolWorking: true,
        isAdmin: true,
        enableModuleName: ['MASTERPLAN_STREETS'],
        isAuthorized: true,
      },
      {
        name: 'MASTERPLAN_SQUARES',
        styleName: 'MASTERPLAN_SQUARES',
        isToolWorking: true,
        isAdmin: true,
        enableModuleName: ['MASTERPLAN_SQUARES'],
        isAuthorized: true,
      },
      {
        name: 'MASTERPLAN_ILLUMINATION',
        styleName: 'MASTERPLAN_ILLUMINATION',
        isToolWorking: true,
        isAdmin: true,
        enableModuleName: ['MASTERPLAN_ILLUMINATION'],
        isAuthorized: true,
      },
      {
        name: 'MASTERPLAN_RP',
        styleName: 'MASTERPLAN_RP',
        isToolWorking: true,
        isAdmin: true,
        enableModuleName: ['MASTERPLAN_RP'],
        isAuthorized: true,
      },
      {
        name: 'SEWER',
        styleName: 'SEWER',
        isToolWorking: true,
        isAdmin: true,
        enableModuleName: ['SEWER_DATA'],
        isAuthorized: true,
      },
      {
        name: 'WATER',
        styleName: 'WATER',
        isToolWorking: true,
        isAdmin: true,
        enableModuleName: ['WATER_DATA'],
        isAuthorized: true,
      },
      {
        name: 'waterModeling',
        styleName: 'waterModeling',
        isToolWorking: true,
        isAdmin: true,
        enableModuleName: ['WATER_MODELING'],
        isAuthorized: true,
      },
      {
        name: 'sewerModeling',
        styleName: 'sewerModeling',
        isToolWorking: true,
        isAdmin: true,
        enableModuleName: ['SEWER_MODELING'],
        isAuthorized: true,
      },
      {
        name: 'VEHICLES',
        styleName: 'VEHICLES',
        isToolWorking: true,
        isAdmin: true,
        enableModuleName: ['VEHICLES'],
        isAuthorized: true,
      },
      {
        name: 'NMT',
        enableModuleName: ['DEM'],
        styleName: 'NMT',
        isToolWorking: true,
        isAdmin: true,
        isAuthorized: true,
      },
      {
        name: 'TASKS',
        styleName: 'TASKS',
        isToolWorking: true,
        isAdmin: true,
        enableModuleName: ['TASKS'],
        isAuthorized: true,
      },
      {
        name: 'TRANSMISSION_EASEMENT',
        styleName: 'TRANSMISSION_EASEMENT',
        isToolWorking: true,
        isAdmin: true,
        enableModuleName: ['TRANSMISSION_EASEMENT'],
        isAuthorized: true,
      },
      {
        name: 'TRANSMISSION_EASEMENT2',
        styleName: 'TRANSMISSION_EASEMENT2',
        isToolWorking: true,
        isAdmin: true,
        enableModuleName: ['TRANSMISSION_EASEMENT_2_MODULE'],
        isAuthorized: true,
      },
      {
        name: 'FOLDERS',
        styleName: 'FOLDERS',
        isToolWorking: true,
        isAdmin: true,
        isAuthorized: true,
        enableModuleName: ['FOLDERS'],
      },
      {
        name: 'hydrants',
        styleName: 'hydrants',
        isToolWorking: true,
        isAdmin: true,
        enableModuleName: ['HYDRANTS_INSPECTIONS'],
        isAuthorized: true,
      },
      {
        name: 'BILLINGS',
        styleName: 'BILLINGS',
        isToolWorking: true,
        isAdmin: true,
        enableModuleName: ['BILLINGS'],
        isAuthorized: true,
      },
      {
        name: 'BUFFER',
        styleName: 'BUFFER',
        isToolWorking: true,
        isAdmin: true,
        enableModuleName: ['BUFFER'],
        isAuthorized: true,
      },
      {
        name: 'INVESTMENTS',
        styleName: 'INVESTMENTS',
        isToolWorking: true,
        isAdmin: true,
        enableModuleName: ['INVESTMENTS'],
        isAuthorized: true,
      },
      {
        name: 'PARCELS',
        styleName: 'PARCELS',
        isToolWorking: true,
        isAdmin: true,
        enableModuleName: ['PARCELS'],
        isAuthorized: true,
      },
      {
        name: 'ULDK_TOOLS',
        styleName: 'ULDK_TOOLS',
        isToolWorking: true,
        isAdmin: true,
        enableModuleName: ['ULDK_TOOLS_MODULE'],
        isAuthorized: true,
      },
      {
        name: 'ADDRESSES',
        styleName: 'ADDRESSES',
        isToolWorking: true,
        isAdmin: true,
        enableModuleName: ['ADDRESSES'],
        isAuthorized: true,
      },
      {
        name: 'SEARCH_OBJECTS',
        styleName: 'SEARCH_OBJECTS',
        isToolWorking: true,
        isAdmin: true,
        enableModuleName: ['SEARCH_OBJECTS_MODULE'],
        isAuthorized: true,
      },
      {
        name: 'DATA_CALCULATOR',
        styleName: 'DATA_CALCULATOR',
        isToolWorking: true,
        isAdmin: true,
        enableModuleName: ['DATA_CALCULATOR_MODULE'],
        isAuthorized: true,
      },
      {
        name: 'EXTERNAL_SYSTEMS',
        styleName: 'EXTERNAL_SYSTEMS',
        isToolWorking: true,
        isAdmin: true,
        enableModuleName: ['EXTERNAL_SYSTEMS'],
        isAuthorized: true,
      },
      {
        name: 'DB2GIS',
        styleName: 'DB2GIS',
        isToolWorking: true,
        isAdmin: true,
        enableModuleName: ['DB2GIS'],
        isAuthorized: true,
      },
      {
        name: 'DB2GISBOX',
        styleName: 'DB2GISBOX',
        isToolWorking: true,
        isAdmin: true,
        enableModuleName: ['EXTERNAL_DB'],
        isAuthorized: true,
      },
      {
        name: 'LOCATION_LAB',
        styleName: 'LOCATION_LAB',
        isToolWorking: true,
        isAdmin: true,
        enableModuleName: ['LOCATION_LAB'],
        isAuthorized: true,
      },
      {
        name: 'DOCUMENTATION',
        styleName: 'DOCUMENTATION',
        isToolWorking: true,
        isAdmin: true,
        enableModuleName: ['DOCUMENTATION'],
        isAuthorized: true,
      },
      {
        name: 'SEWER_ANALYSIS',
        styleName: 'SEWER_ANALYSIS',
        isToolWorking: true,
        isAdmin: true,
        enableModuleName: ['SEWER_ANALYSIS'],
        isAuthorized: true,
      },
      {
        name: 'SCADA_ALARMS',
        styleName: 'SCADA_ALARMS',
        isToolWorking: true,
        isAdmin: true,
        enableModuleName: ['SCADA_ALARMS'],
        isAuthorized: true,
      },
      {
        name: 'VIDEO_MODULE',
        styleName: 'VIDEO_MODULE',
        isToolWorking: true,
        isAdmin: true,
        enableModuleName: ['VIDEO_MODULE'],
        isAuthorized: true,
      },
      {
        name: 'CACHE_LAYER_MODULE',
        styleName: 'CACHE_LAYER_MODULE',
        isToolWorking: true,
        isAdmin: true,
        enableModuleName: ['CACHE_LAYER_MODULE'],
        isAuthorized: true,
      },
      {
        name: 'GEONOTES_MODULE',
        styleName: 'GEONOTES_MODULE',
        isToolWorking: true,
        isAdmin: true,
        enableModuleName: ['GEONOTES_MODULE'],
        isAuthorized: true,
      },
      {
        name: 'parcelReportSettings',
        styleName: 'parcelReportSettings',
        isToolWorking: true,
        isAdmin: true,
        enableModuleName: ['PARCELS_REPORT'],
        isAuthorized: true,
      },
      {
        name: 'rziDocumentation',
        styleName: 'rziDocumentation',
        isToolWorking: true,
        isAdmin: true,
        enableModuleName: ['RZI_DOCUMENTATION'],
        isAuthorized: true,
      },
      {
        name: 'rziMonitoring',
        styleName: 'rziMonitoring',
        isToolWorking: true,
        isAdmin: true,
        enableModuleName: ['RZI_MONITORING'],
        isAuthorized: true,
      },
      {
        name: 'QUICK_PRINT_MODULE',
        styleName: 'QUICK_PRINT_MODULE',
        isToolWorking: true,
        isAdmin: true,
        enableModuleName: ['QUICK_PRINT_MODULE'],
        isAuthorized: true,
      },
      {
        name: 'KOMADRES_ORDERS',
        styleName: 'KOMADRES_ORDERS',
        isToolWorking: true,
        isAdmin: true,
        enableModuleName: ['KOMADRES_ORDERS'],
        isAuthorized: true,
      },
      {
        name: 'SEND_MESSAGE_MODULE',
        styleName: 'SEND_MESSAGE_MODULE',
        isToolWorking: true,
        isAdmin: true,
        enableModuleName: ['SEND_MESSAGE_MODULE'],
        isAuthorized: true,
      },
      {
        name: 'SCADA_JUNCTIONS',
        styleName: 'SCADA_JUNCTIONS',
        isToolWorking: true,
        isAdmin: true,
        enableModuleName: ['SCADA_JUNCTIONS'],
        isAuthorized: true,
      },
      {
        name: 'WATER_BALANCE_MODULE',
        styleName: 'WATER_BALANCE_MODULE',
        isToolWorking: true,
        isAdmin: true,
        enableModuleName: ['WATER_BALANCE_MODULE'],
        isAuthorized: true,
      },
      {
        name: 'CUSTOM_REPORTS_SETTINGS',
        styleName: 'CUSTOM_REPORTS_SETTINGS',
        isToolWorking: true,
        isAdmin: true,
        enableModuleName: ['CUSTOM_REPORTS'],
        isAuthorized: true,
      },
      {
        name: 'SCADA3',
        styleName: 'SCADA3',
        isToolWorking: true,
        isAdmin: true,
        enableModuleName: ['SCADA3'],
        isAuthorized: true,
      },
      {
        name: 'WATER_NETWORK_DEVELOPMENT_MODULE',
        styleName: 'WATER_NETWORK_DEVELOPMENT_MODULE',
        isToolWorking: true,
        isAdmin: true,
        enableModuleName: ['WATER_NETWORK_DEVELOPMENT_MODULE'],
        isAuthorized: true,
      },
      {
        name: 'MEASUREMENTS_MODULE',
        styleName: 'MEASUREMENTS_MODULE',
        isToolWorking: true,
        isAdmin: true,
        enableModuleName: ['MEASUREMENTS_MODULE'],
        isAuthorized: true,
      },
      {
        name: 'FIXED_ASSETS_MODULE',
        styleName: 'FIXED_ASSETS_MODULE',
        isToolWorking: true,
        isAdmin: true,
        enableModuleName: ['FIXED_ASSETS_MODULE'],
        isAuthorized: true,
      },
      {
        name: 'UNASSIGNED_WATERMETERS_MODULE',
        styleName: 'UNASSIGNED_WATERMETERS_MODULE',
        isToolWorking: true,
        isAdmin: true,
        enableModuleName: ['UNASSIGNED_WATERMETERS_MODULE'],
        isAuthorized: true,
      },
      {
        name: 'WATER_NETWORK_PROFILE',
        styleName: 'WATER_NETWORK_PROFILE',
        isToolWorking: true,
        isAdmin: true,
        enableModuleName: ['WATER_NETWORK_PROFILE'],
        isAuthorized: true,
      },
      {
        name: 'WATER_HAMMER',
        styleName: 'WATER_HAMMER',
        isToolWorking: true,
        isAdmin: true,
        enableModuleName: ['WATER_HAMMER'],
        isAuthorized: true,
      },
      {
        name: 'INFRASTRUCTURE_COLLISIONS',
        styleName: 'INFRASTRUCTURE_COLLISIONS',
        isToolWorking: true,
        isAdmin: true,
        enableModuleName: ['INFRASTRUCTURE_COLLISIONS'],
        isAuthorized: true,
      },
      {
        name: 'EXTERNAL_ATTACHMENTS',
        styleName: 'EXTERNAL_ATTACHMENTS',
        isToolWorking: true,
        isAdmin: true,
        enableModuleName: ['EXTERNAL_ATTACHMENTS'],
        isAuthorized: true,
      },
      {
        name: 'MOBILE_DEVICES',
        styleName: 'MOBILE_DEVICES',
        isToolWorking: true,
        isAdmin: true,
        enableModuleName: ['MOBILE_DEVICES'],
        isAuthorized: true,
      },
      {
        name: 'FRESNEL_ZONE',
        styleName: 'FRESNEL_ZONE',
        isToolWorking: true,
        isAdmin: true,
        enableModuleName: ['FRESNEL_AREA'],
        isAuthorized: true,
      },
      {
        name: 'ANTENNA_SIGNAL_DETERMINATION',
        styleName: 'ANTENNA_SIGNAL_DETERMINATION',
        isToolWorking: true,
        isAdmin: true,
        enableModuleName: ['ANTENNA_SIGNAL_BEARING_MODULE'],
        isAuthorized: true,
      },
      {
        name: 'VISIBILITY_ANALYSIS',
        styleName: 'VISIBILITY_ANALYSIS',
        isToolWorking: true,
        isAdmin: true,
        enableModuleName: ['VIEWSHED'],
        isAuthorized: true,
      },
      {
        name: 'REALTIME_STATIONS_MEASUREMENTS',
        styleName: 'REALTIME_STATIONS_MEASUREMENTS',
        isToolWorking: true,
        isAdmin: true,
        enableModuleName: ['REALTIME_STATIONS_MEASUREMENTS'],
        isAuthorized: true,
      },
      {
        name: 'ANTENNA_BEARING_HISTORY_MODULE',
        styleName: 'ANTENNA_BEARING_HISTORY_MODULE',
        isToolWorking: true,
        isAdmin: true,
        enableModuleName: ['ANTENNA_BEARING_HISTORY_MODULE'],
        isAuthorized: true,
      },
      {
        name: 'SMART_MATCH',
        styleName: 'SMART_MATCH',
        isToolWorking: true,
        isAdmin: true,
        enableModuleName: ['SMART_MATCH_MODULE'],
        isAuthorized: true,
      },
      {
        name: 'PRINTING_2_MODULE',
        styleName: 'PRINTING_2_MODULE',
        isToolWorking: true,
        isAdmin: true,
        enableModuleName: ['PRINTING_2_MODULE'],
        isAuthorized: true,
      },
      {
        name: 'OZE_SEARCH_MODULE',
        styleName: 'OZE_SEARCH_MODULE',
        isToolWorking: true,
        isAdmin: true,
        enableModuleName: ['OZE_SEARCH_MODULE'],
        isAuthorized: true,
      },
      {
        name: 'FORM_PRINT',
        styleName: 'FORM_PRINT',
        isToolWorking: true,
        isAdmin: true,
        enableModuleName: ['FORM_PRINT'],
        isAuthorized: true,
      },
      {
        name: 'DATA_SOURCES_SQL',
        styleName: 'DATA_SOURCES_SQL',
        isToolWorking: true,
        isAdmin: true,
        enableModuleName: ['DATASOURCES_SQL'],
        isAuthorized: true,
      },
      {
        name: 'INSPECTIONS_MODULE',
        styleName: 'INSPECTIONS_MODULE',
        isToolWorking: true,
        isAdmin: true,
        enableModuleName: ['INSPECTIONS_MODULE'],
        isAuthorized: true,
      },
      {
        name: 'JOINS',
        styleName: 'JOINS',
        isToolWorking: true,
        isAdmin: true,
        enableModuleName: ['JOINS'],
        isAuthorized: true,
      },
      {
        name: 'DATA_TREE_MODULE',
        styleName: 'DATA_TREE_MODULE',
        isToolWorking: true,
        isAdmin: true,
        enableModuleName: ['DATA_TREE_MODULE'],
        isAuthorized: true,
      },
      {
        name: 'DATA_ACCESS_RESTRICTIONS',
        styleName: 'DATA_ACCESS_RESTRICTIONS',
        isToolWorking: true,
        isAdmin: true,
        enableModuleName: ['DATASOURCES_BY_GROUP_MODULE'],
        isAuthorized: true,
      },
      {
        name: 'MASTERPLAN_SQUARES_2',
        styleName: 'MASTERPLAN_SQUARES_2',
        isToolWorking: true,
        isAdmin: true,
        enableModuleName: ['MASTERPLAN_2_SQUARES_MODULE'],
        isAuthorized: true,
      },
      {
        name: 'MASTERPLAN_ILLUMINATION_2',
        styleName: 'MASTERPLAN_ILLUMINATION_2',
        isToolWorking: true,
        isAdmin: true,
        enableModuleName: ['MASTERPLAN_2_ILLUMINATION_MODULE'],
        isAuthorized: true,
      },
      {
        name: 'MASTERPLAN_STREETS_2',
        styleName: 'MASTERPLAN_STREETS_2',
        isToolWorking: true,
        isAdmin: true,
        enableModuleName: ['MASTERPLAN_2_STREETS'],
        isAuthorized: true,
      },
      {
        name: 'PAIH_MIGRATOR',
        styleName: 'PAIH_MIGRATOR',
        isToolWorking: true,
        isAdmin: true,
        enableModuleName: ['PAIH_MIGRATOR_MODULE'],
        isAuthorized: true,
      },
      {
        name: 'ILS_EASY',
        styleName: 'ILS_EASY',
        isToolWorking: true,
        isAdmin: true,
        enableModuleName: ['ILS_EASY_MODULE'],
        isAuthorized: true,
      },
      {
        name: 'TREEVIEWS',
        styleName: 'TREEVIEWS',
        isToolWorking: true,
        isAdmin: true,
        enableModuleName: ['TREE_VIEW'],
        isAuthorized: true,
      },
      {
        name: 'ULDK_SEARCH',
        styleName: 'ULDK_SEARCH',
        isToolWorking: true,
        isAdmin: true,
        enableModuleName: ['ULDK_SEARCH'],
        isAuthorized: true,
      },
      {
        name: 'STREET_LAMP_INVENTORY',
        styleName: 'STREET_LAMP_INVENTORY',
        isToolWorking: true,
        isAdmin: true,
        enableModuleName: ['ILS_SESSIONS_MODULE'],
        isAuthorized: true,
      },
      {
        name: 'ORDERS',
        styleName: 'ORDERS_MODULE',
        isToolWorking: true,
        isAdmin: true,
        enableModuleName: ['ORDERS_MODULE'],
        isAuthorized: true,
      },
      {
        name: 'AREA_REPORT',
        styleName: 'AREA_REPORT',
        isToolWorking: true,
        isAdmin: true,
        enableModuleName: ['AREA_REPORT_MODULE'],
        isAuthorized: true,
      },
    ],
  },
  {
    name: 'settings',
    styleName: 'settings',
    icon: 'mdi-cog',
    isList: true,
    items: [
      {
        name: 'appSettings',
        styleName: 'appSettings',
        isToolWorking: true,
        isAdmin: true,
      },
      {
        name: 'loginSettings',
        styleName: 'loginSettings',
        isToolWorking: true,
        isAdmin: true,
      },
      {
        name: 'printsSettings',
        styleName: 'prints',
        isToolWorking: true,
        isAdmin: true,
        enableModuleName: ['MAP_PRINT'],
        isAuthorized: true,
      },
      {
        name: 'masterplanPdfSettings',
        customNamePath: 'navbar.masterplanPdfSettings',
        styleName: 'masterplanPdfSettings',
        isToolWorking: true,
        isAdmin: true,
        enableModuleName: ['MASTERPLAN_STREETS', 'MASTERPLAN_ILLUMINATION', 'MASTERPLAN_SQUARES', 'MASTERPLAN_RP'],
        isAuthorized: true,
        oneModuleRequired: true,
      },
    ],
  },
  {
    name: 'integrations',
    styleName: 'integrations',
    icon: 'mdi-connection',
    isAdmin: true,
    isToolWorking: true,
  },
  {
    name: 'other',
    styleName: 'other',
    icon: 'mdi-plus-box-multiple',
    isList: true,
    items: [
      {
        name: 'eventsLog',
        styleName: 'eventsLog',
        isToolWorking: true,
        isAdmin: true,
      },
      {
        name: 'ACTIVITY_LOG',
        styleName: 'ACTIVITY_LOG',
        isToolWorking: true,
        isAdmin: true,
        enableModuleName: ['ACTIVITY_LOG_MODULE'],
        isAuthorized: true,
      },
    ],
  },
  {
    name: 'superadmin',
    styleName: 'superadmin',
    icon: '$superadmin',
    isToolWorking: true,
    superAdmingPermission: true,
    isAdmin: true,
  },
  {
    name: 'devTab',
    styleName: 'devTab',
    icon: 'mdi-dev-to',
    isToolWorking: true,
    superAdmingPermission: true,
    isAdmin: true,
  },
];
