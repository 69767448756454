/* eslint-disable */
/*
  Variables are stored in JS file, because there might be some cases where they
  will be required outside .vue file or before Vue instance had been initialized (no `this` access).
*/

const defaultAdminTab = 'admin';

const defaultEpsg = 'EPSG:3857';
const defaultEpsgName = 'EPSG:3857 (Google Mercator)';
const defaultSRID = 3857;
const defaultEpsgDefinition =
  '+proj=merc +a=6378137 +b=6378137 +lat_ts=0.0 +lon_0=0.0 +x_0=0.0 +y_0=0 +k=1.0 +units=m +nadgrids=@null +wktext  +no_defs';
const defaultEpsgExtent = [-20026376.39, -20048966.1, 20026376.39, 20048966.1];

const defaultDocumentationHost = 'https://dokumentacja.gisbox.pl';

const defaultFrontendCoordinateSystems = [
  {
    auth_srid: defaultSRID,
    name: defaultEpsgName,
    proj4text: defaultEpsgDefinition,
  },
  {
    auth_srid: 4326,
    name: 'EPSG:4326 (GPS)',
    proj4text: '',
  },
];

const minEditingZoom = 16;
const defaultMinZoom = 0;
const defaultMaxZoom = 28;

const systemAttributes = [
  'id',
  '_id',
  'history_count',
  'notes_count',
  'attachments_count',
  'create_datetime',
  'create_user',
  'update_datetime',
  'update_user',
  'geom',
];

const dashboardWmts = {
  parcels: {
    service_layers_names: ['dzialki_numery'],
    url: 'https://powerwms.gisbox.pl/geoserver/gisbox/gwc/service/wmts',
    parameters: {
      CRS: 'EPSG:3857',
      format: 'image/png',
      version: '1.0.0',
      options: {
        extent: [1467590.9428710975, 6245057.744086148, 2827902.516558882, 7355611.327886885],
        matrixIds: [
          'EPSG:3857:0',
          'EPSG:3857:1',
          'EPSG:3857:2',
          'EPSG:3857:3',
          'EPSG:3857:4',
          'EPSG:3857:5',
          'EPSG:3857:6',
          'EPSG:3857:7',
          'EPSG:3857:8',
          'EPSG:3857:9',
          'EPSG:3857:10',
          'EPSG:3857:11',
          'EPSG:3857:12',
          'EPSG:3857:13',
          'EPSG:3857:14',
          'EPSG:3857:15',
          'EPSG:3857:16',
          'EPSG:3857:17',
          'EPSG:3857:18',
          'EPSG:3857:19',
          'EPSG:3857:20',
        ],
        origins: [
          [-20037508.34, 20037508],
          [-20037508.34, 20037508],
          [-20037508.34, 20037508],
          [-20037508.34, 20037508],
          [-20037508.34, 20037508],
          [-20037508.34, 20037508],
          [-20037508.34, 20037508],
          [-20037508.34, 20037508],
          [-20037508.34, 20037508],
          [-20037508.34, 20037508],
          [-20037508.34, 20037508],
          [-20037508.34, 20037508],
          [-20037508.34, 20037508],
          [-20037508.34, 20037508],
          [-20037508.34, 20037508],
          [-20037508.34, 20037508],
          [-20037508.34, 20037508],
          [-20037508.34, 20037508],
          [-20037508.34, 20037508],
          [-20037508.34, 20037508],
          [-20037508.34, 20037508],
        ],
        resolutions: [
          156543.03390625, 78271.516953125, 39135.7584765625, 19567.87923828125, 9783.939619140625, 4891.9698095703125,
          2445.9849047851562, 1222.9924523925781, 611.4962261962891, 305.74811309814453, 152.87405654907226,
          76.43702827453613, 38.218514137268066, 19.109257068634033, 9.554628534317017, 4.777314267158508,
          2.388657133579254, 1.194328566789627, 0.5971642833948135, 0.2985821416974068, 0.1492910708487034,
        ],
        style: '',
        tileSizes: [
          256, 256, 256, 256, 256, 256, 256, 256, 256, 256, 256, 256, 256, 256, 256, 256, 256, 256, 256, 256, 256,
        ],
      },
    },
  },
  orto: {
    parameters: {
      CRS: 'EPSG:3857',
      format: 'image/jpeg',
      options: {
        extent: [1536208.9729471754, 6240993.463458903, 2716195.5753558753, 7361866.113051185],
        matrixIds: [
          'EPSG:3857:0',
          'EPSG:3857:1',
          'EPSG:3857:2',
          'EPSG:3857:3',
          'EPSG:3857:4',
          'EPSG:3857:5',
          'EPSG:3857:6',
          'EPSG:3857:7',
          'EPSG:3857:8',
          'EPSG:3857:9',
          'EPSG:3857:10',
          'EPSG:3857:11',
          'EPSG:3857:12',
          'EPSG:3857:13',
          'EPSG:3857:14',
          'EPSG:3857:15',
          'EPSG:3857:16',
          'EPSG:3857:17',
          'EPSG:3857:18',
          'EPSG:3857:19',
          'EPSG:3857:20',
          'EPSG:3857:21',
          'EPSG:3857:22',
          'EPSG:3857:23',
        ],
        origins: [
          [-20037508.342787, 20037508.342787],
          [-20037508.342787, 20037508.342787],
          [-20037508.342787, 20037508.342787],
          [-20037508.342787, 20037508.342787],
          [-20037508.342787, 20037508.342787],
          [-20037508.342787, 20037508.342787],
          [-20037508.342787, 20037508.342787],
          [-20037508.342787, 20037508.342787],
          [-20037508.342787, 20037508.342787],
          [-20037508.342787, 20037508.342787],
          [-20037508.342787, 20037508.342787],
          [-20037508.342787, 20037508.342787],
          [-20037508.342787, 20037508.342787],
          [-20037508.342787, 20037508.342787],
          [-20037508.342787, 20037508.342787],
          [-20037508.342787, 20037508.342787],
          [-20037508.342787, 20037508.342787],
          [-20037508.342787, 20037508.342787],
          [-20037508.342787, 20037508.342787],
          [-20037508.342787, 20037508.342787],
          [-20037508.342787, 20037508.342787],
          [-20037508.342787, 20037508.342787],
          [-20037508.342787, 20037508.342787],
          [-20037508.342787, 20037508.342787],
        ],
        resolutions: [
          156543.03392737397, 78271.51696368684, 39135.75848184355, 19567.879240921644, 9783.939620460822,
          4891.969810230411, 2445.9849051152055, 1222.9924525576027, 611.4962262789337, 305.7481131393345,
          152.87405656979956, 76.43702828476748, 38.21851414238374, 19.10925707119187, 9.554628535595935,
          4.777314267930259, 2.3886571339651295, 1.1943285668502728, 0.5971642835574285, 0.29858214164642227,
          0.14929107082321114, 0.07464553541160557, 0.037322767705802784, 0.018661383852901392,
        ],
        style: '',
        tileSizes: [
          256, 256, 256, 256, 256, 256, 256, 256, 256, 256, 256, 256, 256, 256, 256, 256, 256, 256, 256, 256, 256, 256,
          256, 256,
        ],
      },
      version: '1.0.0',
    },
    service_layers_names: ['ORTOFOTOMAPA'],
    url: 'https://mapy.geoportal.gov.pl/wss/service/PZGIK/ORTO/WMTS/StandardResolution',
  },
};

export {
  defaultFrontendCoordinateSystems,
  defaultEpsg,
  defaultEpsgName,
  defaultEpsgDefinition,
  defaultAdminTab,
  defaultEpsgExtent,
  defaultDocumentationHost,
  defaultSRID,
  defaultMinZoom,
  defaultMaxZoom,
  minEditingZoom,
  systemAttributes,
  dashboardWmts,
};
