import { get } from 'vuex-pathify';

export default {
  computed: {
    mapPanelDataFetched: get('map/mapPanelDataFetched'),
    permissions: get('users/toolsPermissions'),
    settings: get('admin/settingsValues'),
    additionalModules: get('admin/additionalModules'),
    isIdentificationToolActive: get('tools/toolStatus@isIdentificationToolActive'),
    isParcelReportToolActive: get('tools/toolStatus@isParcelReportToolActive'),
    isAreaReportToolActive: get('tools/toolStatus@isAreaReportToolActive'),
    isReverseGeocoderToolActive: get('tools/toolStatus@isReverseGeocoderToolActive'),
    isSpatialBookmarksToolActive: get('tools/toolStatus@isSpatialBookmarksToolActive'),
    isSnappingConfigToolActive: get('tools/toolStatus@isSnappingConfigToolActive'),
    isCoordinatesToolActive: get('tools/toolStatus@isCoordinatesToolActive'),
    isMeasurementsToolActive: get('tools/toolStatus@isMeasurementsToolActive'),
    isStreetViewToolActive: get('tools/toolStatus@isStreetViewToolActive'),
    isGoogleMapsToolActive: get('tools/toolStatus@isGoogleMapsToolActive'),
    activeTool: get('tools/activeTool'),
    isMeasurementsConfigured: get('admin/additionalModules@MEASUREMENTS_MODULE.configured'),
    isSearchObjectsConfigured: get('admin/additionalModules@SEARCH_OBJECTS_MODULE.configured'),
    isNmtConfigured: get('admin/additionalModules@DEM.configured'),
    isSelectionActive: get('tools/toolStatus@isSelectionActive'),
    parcelsProvider: get('admin/settingsValues@parcels_provider'),
    projectElements: get('layers/project@layers'),
    parcelsMapping: get('admin/modulesMapping@parcels'),
    addressesMapping: get('admin/modulesMapping@addresses'),
    modulesMapping: get('admin/modulesMapping'),
    isQuickPrintToolLoading: get('tools/isQuickPrintToolLoading'),
    isActiveToolNotReplaceable: get('tools/isActiveToolNotReplaceable'),
    // isAreaReportConfigured() {
    //   return Boolean(this.modulesMapping.area_report?.settings?.layer_id);
    // },
    isSearchParcelsConfigured() {
      if (this.settings?.parcels_provider === 'uldk') {
        return true;
      } else {
        const elements = ['attribute_community', 'attribute_region', 'attribute_number', 'parcels_datasource'];
        return elements.every(element => (this.parcelsMapping || {})[element]);
      }
    },
    isSearchAddressesConfigured() {
      if (this.settings?.addresses_provider === 'gugik_api') {
        return true;
      } else {
        const elements = [
          'attribute_place',
          'attribute_postal_code',
          'attribute_street',
          'attribute_number',
          'addresses_datasource',
        ];
        return elements.every(element => (this.addressesMapping || {})[element]);
      }
    },
    isNextViewDisabled: get('tools/isNextViewDisabled'),
    isPreviousViewDisabled: get('tools/isPreviousViewDisabled'),
    isQgisPluginGisSupport: get('admin/settingsValues@qgis_plugin_gis_support_access_enabled'),
    currentUser: get('users/currentUser'),
    isAdmin() {
      return this.currentUser.is_admin || this.currentUser.is_superadmin;
    },
    tools() {
      return [
        {
          icon: 'mdi-cursor-default-click',
          name: 'identification',
          skipAuthorization: true,
          hasState: true,
          disabled:
            !this.mapPanelDataFetched ||
            (this.isActiveToolNotReplaceable && this.activeTool !== 'moduleIdentification'),
          isActive: this.isIdentificationToolActive,
          actionParams: {
            layersFilter: layer => layer.get('isSpecial') === false,
          },
          tooltip: this.mapPanelDataFetched
            ? this.isActiveToolNotReplaceable
              ? this.$i18n.t('button.oneToolActive')
              : this.$i18n.t('navbar.identification', {
                  prefix: this.$i18n.t(`navbar.${this.isIdentificationToolActive ? 'off' : 'on'}`),
                })
            : this.$i18n.t('default.downloadingDataInProgress'),
        },
        {
          icon: '$coordinates',
          name: 'coordinates',
          hasState: true,
          isActive: this.isCoordinatesToolActive,
          disabled: this.isActiveToolNotReplaceable,
          enableModuleName: ['COORDINATES'],
          hasCustomAction: false,
          color: 'font',
          tooltip: this.isActiveToolNotReplaceable
            ? this.$i18n.t('button.oneToolActive')
            : this.additionalModules['COORDINATES']?.verbose_name,
        },
        {
          icon: 'mdi-selection-marker',
          name: 'locationSelection',
          hasCustomAction: false,
          enableModuleName: ['SELECT_BY_LOCATION_MODULE'],
          tooltip: this.additionalModules['SELECT_BY_LOCATION_MODULE']?.verbose_name,
        },
        {
          fontSize: '14px',
          icon: 'DXF',
          name: 'dxf',
          routeName: 'dxf',
          hasCustomAction: false,
          enableModuleName: ['ZDM_DATA', 'DXF_IMPORT'],
          enableLogic: 'ANY',
          tooltip: this.$i18n.t('navbar.importDxf'),
        },
        {
          icon: 'mdi-ruler',
          name: 'measurements',
          skipAuthorization: true,
          hasState: true,
          disabled: this.isActiveToolNotReplaceable,
          isActive: this.isMeasurementsToolActive,
          tooltip: this.isActiveToolNotReplaceable
            ? this.$i18n.t('button.oneToolActive')
            : this.$i18n.t('navbar.measurements', {
                prefix: this.$i18n.t(`navbar.${this.isMeasurementsToolActive ? 'off' : 'on'}`),
              }),
        },
        {
          icon: 'mdi-plus-box',
          name: 'zoomIn',
          skipAuthorization: true,
          tooltip: this.$i18n.t('navbar.zoomIn'),
        },
        {
          icon: 'mdi-minus-box',
          name: 'zoomOut',
          skipAuthorization: true,
          tooltip: this.$i18n.t('navbar.zoomOut'),
        },
        {
          icon: 'mdi-fullscreen',
          name: 'defaultView',
          skipAuthorization: true,
          tooltip: this.$i18n.t('navbar.defaultView'),
        },
        {
          icon: 'mdi-skip-previous',
          name: 'previuosView',
          skipAuthorization: true,
          disabled: this.isPreviousViewDisabled,
          tooltip: this.isPreviousViewDisabled
            ? this.$i18n.t('navbar.noPreviousView')
            : this.$i18n.t('navbar.previuosView'),
        },
        {
          icon: 'mdi-skip-next',
          name: 'nextView',
          skipAuthorization: true,
          disabled: this.isNextViewDisabled,
          tooltip: this.isNextViewDisabled ? this.$i18n.t('navbar.noNextView') : this.$i18n.t('navbar.nextView'),
        },
        {
          icon: '$spatialBookmarks',
          name: 'spatialBookmarks',
          enableModuleName: ['SPATIAL_BOOKMARKS'],
          isActive: this.isSpatialBookmarksToolActive,
          tooltip: this.isSpatialBookmarksToolActive ? '' : this.additionalModules['SPATIAL_BOOKMARKS']?.verbose_name,
        },
        {
          icon: 'mdi-magnet',
          name: 'snappingConfig',
          enableModuleName: ['ADVANCED_DIGITIZING'],
          enableSettingName: ['snapping_config_enabled'],
          isActive: this.isSnappingConfigToolActive,
        },
        {
          icon: 'mdi-pencil-ruler',
          name: 'measurementsModule',
          routeName: 'measurementsModule',
          enableModuleName: ['MEASUREMENTS_MODULE'],
          enableSettingName: ['measurements_module_enabled'],
          hasCustomAction: false,
          disabled: !this.isMeasurementsConfigured,
          tooltip: this.isMeasurementsConfigured
            ? this.additionalModules['MEASUREMENTS_MODULE']?.verbose_name
            : this.$i18n.t('snackbar.modulesNotConfigurate.MEASUREMENTS_MODULE', {
                suffix: this.additionalModules['MEASUREMENTS_MODULE']?.verbose_name,
              }),
        },
        {
          fontSize: '25px',
          icon: '$layerDownload',
          name: 'databoxData',
          enableModuleName: ['DATABOX_DATA_MODULE'],
          enableSettingName: ['databox_data_download'],
          tooltip: this.$i18n.t('admin.databoxDataDownload'),
        },
        {
          divider: true,
        },
        {
          icon: 'mdi-printer',
          name: 'prints',
          routeName: 'prints',
          enableModuleName: ['MAP_PRINT'],
          hasCustomAction: false,
          tooltip: this.$i18n.t('navbar.print'),
        },
        {
          icon: 'mdi-printer',
          name: 'printing',
          routeName: 'printing',
          enableModuleName: ['PRINTING_2_MODULE'],
          enableSettingName: ['printing_2_module_enabled'],
          hasCustomAction: false,
          tooltip: this.$i18n.t('navbar.print'),
        },
        {
          icon: '$quickprint',
          name: 'quickprint',
          isLoading: this.isQuickPrintToolLoading,
          tooltip: this.isQuickPrintToolLoading
            ? this.$i18n.t('snackbar.generatingQuickPrint')
            : this.additionalModules['QUICK_PRINT_MODULE']?.verbose_name,
          fontSize: '24px',
          enableModuleName: ['QUICK_PRINT_MODULE'],
          enableSettingName: ['quick_print_module_enabled'],
        },
        {
          icon: 'mdi-google-street-view',
          name: 'streetview',
          enableModuleName: ['GOOGLE_MAPS'],
          enableSettingName: ['street_view_tool_enabled'],
          hasState: true,
          disabled: this.isActiveToolNotReplaceable,
          isActive: this.isStreetViewToolActive,
          tooltip: this.isActiveToolNotReplaceable
            ? this.$i18n.t('button.oneToolActive')
            : `${this.$i18n.t(`navbar.${this.isStreetViewToolActive ? 'off' : 'on'}`)} ${this.$i18n.t(
                'admin.streetView'
              )}`,
        },
        {
          icon: '$googleMaps',
          name: 'googleMaps',
          enableModuleName: ['GOOGLE_MAPS'],
          enableSettingName: ['google_maps_tool_enabled'],
          hasState: true,
          disabled: this.isActiveToolNotReplaceable,
          isActive: this.isGoogleMapsToolActive,
          tooltip: this.isActiveToolNotReplaceable
            ? this.$i18n.t('button.oneToolActive')
            : `${this.$i18n.t(`navbar.${this.isGoogleMapsToolActive ? 'off' : 'on'}`)} ${this.$i18n.t(
                'admin.googleMaps'
              )}`,
        },
        {
          icon: 'mdi-chart-areaspline',
          name: 'nmt',
          routeName: 'nmtMap',
          hasCustomAction: false,
          enableModuleName: ['DEM'],
          enableSettingName: ['dem_module_enabled'],
          disabled: !this.isNmtConfigured,
          tooltip: this.isNmtConfigured
            ? this.additionalModules['DEM']?.verbose_name
            : this.$i18n.t('snackbar.modulesNotConfigurate.NMT', {
                suffix: this.additionalModules['DEM']?.verbose_name,
              }),
        },
        {
          divider: true,
        },
        {
          icon: 'mdi-card-search',
          name: 'searchObjects',
          enableModuleName: ['SEARCH_OBJECTS_MODULE'],
          enableSettingName: ['search_objects_module_enabled'],
          disabled: !this.mapPanelDataFetched || !this.isSearchObjectsConfigured,
          tooltip: this.isSearchObjectsConfigured
            ? this.mapPanelDataFetched
              ? this.additionalModules['SEARCH_OBJECTS_MODULE']?.verbose_name
              : this.$i18n.t('default.downloadingDataInProgress')
            : this.$i18n.t('snackbar.modulesNotConfigurate.searchObjects', {
                suffix: this.additionalModules['SEARCH_OBJECTS_MODULE']?.verbose_name,
              }),
        },
        {
          icon: 'mdi-layers-search',
          name: 'searchParcel',
          routeName: 'uldk',
          hasCustomAction: this.parcelsProvider !== 'uldk',
          enableModuleName: ['PARCELS'],
          enableSettingName: ['parcels_module_enabled'],
          disabled: !this.isSearchParcelsConfigured,
          tooltip: this.isSearchParcelsConfigured
            ? this.additionalModules['PARCELS']?.verbose_name
            : this.$i18n.t('snackbar.modulesNotConfigurate.searchParcels', {
                suffix: this.additionalModules['PARCELS']?.verbose_name,
              }),
        },
        {
          fontSize: '25px',
          icon: '$uldk',
          name: 'uldk',
          enableModuleName: ['ULDK_SEARCH'],
          enableSettingName: ['uldk_search_module_enabled'],
          tooltip: this.additionalModules['ULDK_SEARCH']?.verbose_name,
        },
        {
          icon: '$investmentAreaSearch',
          name: 'investmentAreaSearch',
          enableModuleName: ['OZE_SEARCH_MODULE'],
          enableSettingName: ['oze_search_module_enabled'],
          tooltip: this.additionalModules['OZE_SEARCH_MODULE']?.verbose_name,
        },
        // ...(this.isAreaReportConfigured
        //   ? [
        //       {
        //         fontSize: '24px',
        //         icon: '$areaReport',
        //         name: 'areaReport',
        //         isActive: this.isAreaReportToolActive,
        //         enableModuleName: ['AREA_REPORT_MODULE'],
        //         enableSettingName: ['area_report_module_enabled'],
        //         hasState: true,
        //         hasCustomAction: true,
        //         tooltip: this.isActiveToolNotReplaceable
        //           ? this.$i18n.t('button.oneToolActive')
        //           : this.additionalModules['AREA_REPORT_MODULE']?.verbose_name,
        //       },
        //     ]
        //   : []),
        {
          icon: '$routingService',
          name: 'routingService',
          enableModuleName: ['ROUTING_SERVICE_MODULE'],
          tooltip: this.additionalModules['ROUTING_SERVICE_MODULE']?.verbose_name,
        },
        {
          icon: 'mdi-home-search',
          name: 'searchAddress',
          enableModuleName: ['ADDRESSES'],
          enableSettingName: ['addresses_module_enabled'],
          disabled: !this.isSearchAddressesConfigured,
          tooltip: this.isSearchAddressesConfigured
            ? this.additionalModules['ADDRESSES']?.verbose_name
            : this.$i18n.t('snackbar.modulesNotConfigurate.searchAddresses', {
                suffix: this.additionalModules['ADDRESSES']?.verbose_name,
              }),
        },
        {
          icon: 'mdi-google-maps',
          name: 'searchGoogle',
          enableModuleName: ['GOOGLE_GEOCODE'],
          disabled: !this.settings?.google_maps_api_key_enabled,
          tooltip: this.settings?.google_maps_api_key_enabled
            ? this.additionalModules['GOOGLE_GEOCODE']?.verbose_name
            : this.$i18n.t('snackbar.modulesNotConfigurate.searchGoogle'),
        },
        {
          fontSize: '24px',
          icon: '$parcelReport',
          name: 'parcelReport',
          isActive: this.isParcelReportToolActive,
          enableModuleName: ['PARCELS_REPORT', 'PARCELS'],
          enableSettingName: ['parcel_report_module_enabled', 'parcels_module_enabled'],
          enableLogic: 'ALL',
          hasState: true,
          hasCustomAction: true,
          disabled: !this.isSearchParcelsConfigured || this.isActiveToolNotReplaceable,
          tooltip: this.isActiveToolNotReplaceable
            ? this.$i18n.t('button.oneToolActive')
            : !this.isSearchParcelsConfigured
              ? this.$i18n.t('snackbar.modulesNotConfigurate.searchParcels', {
                  suffix: this.additionalModules['ADDRESSES']?.verbose_name,
                })
              : this.additionalModules['PARCELS_REPORT']?.verbose_name,
        },
        {
          icon: '$reverseGeocoder',
          name: 'reverseGeocoder',
          enableModuleName: ['ADDRESSES', 'PARCELS'],
          enableSettingName: ['addresses_module_enabled', 'parcels_module_enabled'],
          enableLogic: 'ALL',
          isActive: this.isReverseGeocoderToolActive,
          hasState: true,
          tooltip: !this.isSearchAddressesConfigured
            ? this.$i18n.t('snackbar.modulesNotConfigurate.searchAddresses', {
                suffix: this.additionalModules['ADDRESSES']?.verbose_name,
              })
            : !this.isSearchParcelsConfigured
              ? this.$i18n.t('snackbar.modulesNotConfigurate.searchParcels', {
                  suffix: this.additionalModules['ADDRESSES']?.verbose_name,
                })
              : this.$i18n.t('admin.reverseGeocoderSearch'),
          hasCustomAction: true,
          disabled:
            !this.isSearchAddressesConfigured || !this.isSearchParcelsConfigured || this.isActiveToolNotReplaceable,
        },
        {
          icon: '$layersSearch',
          name: 'parcels',
          enableModuleName: ['PARCELS_SEARCH'],
          tooltip: this.additionalModules['PARCELS_SEARCH']?.verbose_name,
          hasCustomAction: false,
        },
        {
          divider: true,
        },
        {
          icon: 'mdi-car',
          name: 'VEHICLES',
          hasCustomAction: false,
          enableModuleName: ['VEHICLES'],
          enableSettingName: ['vehicles_module_enabled'],
          moduleLayerTooltipPath: 'navbar.toggleVehicles',
          moduleLayerTooltipSuffix: this.additionalModules['VEHICLES']?.verbose_name.toLowerCase(),
          moduleLayerId: this.modulesMapping?.vehicles?.layer_id,
        },
        ...(import.meta.env.VUE_APP_APP_KIND !== 'zdm'
          ? [
              {
                icon: 'mdi-cellphone',
                name: 'MOBILE',
                enableModuleName: [this.getMobileName()],
              },
            ]
          : []),
        ...(!this.isQgisPluginGisSupport
          ? [
              {
                icon: '$qgis',
                name: 'QGIS_PLUGIN',
                enableModuleName: ['QGIS_PLUGIN'],
                color: 'font',
                tooltip: this.$i18n.t('navbar.QGIS_PLUGIN'),
              },
            ]
          : []),
        {
          divider: true,
        },
        {
          icon: 'mdi-text-box-plus',
          name: 'addDocument',
          enableModuleName: ['DOCUMENTATION'],
          enableSettingName: ['documentation_module_enabled'],
          tooltip: this.$i18n.t('navbar.addNewDocument'),
        },
        {
          icon: 'mdi-clipboard-text-clock',
          name: 'HISTORY',
          hasCustomAction: false,
          enableModuleName: ['HISTORY'],
          tooltip: this.additionalModules['HISTORY']?.verbose_name,
          moduleLayerId: this.modulesMapping?.history?.history_layer_id,
        },
        {
          name: 'zdmStreets',
          enableModuleName: ['ZDM_STREETS'],
        },
        ...(this.isAdmin
          ? [
              {
                name: 'zdmPowerSource',
                enableModuleName: ['ZDM_DATA'],
              },
            ]
          : []),
      ];
    },
    filteredTools() {
      return this.tools
        .filter(tool => {
          if (tool.skipAuthorization || tool.divider) return true;
          if (!tool.enableLogic || tool.enableLogic === 'ALL') {
            const hasPermission = tool.enableModuleName
              ? tool.enableModuleName.find(name => !this.permissions[name]?.main_value > 0)
                ? false
                : true
              : true;
            const isEnabled = tool.enableSettingName
              ? tool.enableSettingName.find(name => !this.settings[name])
                ? false
                : true
              : true;
            const areAdditionalModulesEnabled = tool.enableModuleName.find(
              name => !this.additionalModules[name]?.enabled
            )
              ? false
              : true;
            return isEnabled && hasPermission && areAdditionalModulesEnabled;
          } else if (tool.enableLogic === 'ANY') {
            const hasPermission = tool.enableModuleName
              ? tool.enableModuleName.find(name => this.permissions[name]?.main_value > 0)
                ? true
                : false
              : true;
            const isEnabled = tool.enableSettingName
              ? tool.enableSettingName.find(name => this.settings[name])
                ? true
                : false
              : true;
            const areAdditionalModulesEnabled = tool.enableModuleName.find(
              name => this.additionalModules[name]?.enabled
            )
              ? true
              : false;
            return isEnabled && hasPermission && areAdditionalModulesEnabled;
          }
        })
        .filter((tool, index, arr) => {
          if (tool.divider) {
            const indexOfDividerBefore = arr.findIndex((item, i) => i < index && item.divider);
            if (!indexOfDividerBefore) return true;
            else if (indexOfDividerBefore === index - 1 || index === arr.length - 1) return false;
          }
          return true;
        });
    },
  },
  methods: {
    getMobileName() {
      if (import.meta.env.VUE_APP_APP_KIND === 'zdm') {
        return 'ZDM_MOBILE';
      } else if (import.meta.env.VUE_APP_APP_KIND === 'aquagis_offline') {
        return 'AQUAGIS_OFFLINE_MOBILE';
      } else {
        return 'AQUAGIS_MOBILE';
      }
    },
  },
};
