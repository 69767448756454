<template>
  <span>
    <treeview-draggable
      v-model="computedLayers"
      element-children-key="layers"
      element-uid-key="uniqueTreeviewKey"
      group-open-key="open"
      empty-group-translation-path="admin.noLayers"
    >
      <template #label="{ item }">
        <v-col cols="12" class="pa-0">
          <v-row no-gutters align="center" class="flex-nowrap">
            <v-col cols="auto" class="pa-0">
              <v-tooltip bottom color="font" :disabled="!item.selectableTooltip">
                <template #activator="{ on }">
                  <span v-on="on">
                    <span v-if="item.layers" :class="{ 'layer-treeview-layer-checkbox': !item.selectableDisabled }">
                      <v-icon
                        :disabled="item.selectableDisabled"
                        :color="getGroupValue(item) ? 'primary' : ''"
                        @click="selectElement(item.uniqueTreeviewKey, !getGroupValue(item))"
                        @click.native.stop
                      >
                        {{ getGroupIcon(item) }}
                      </v-icon>
                    </span>
                    <v-checkbox
                      v-else
                      v-model="item.visible"
                      @change="selectElement(item.uniqueTreeviewKey, $event)"
                      @click.native.stop
                      :disabled="item.selectableDisabled"
                      hide-details
                      class="pa-0 pr-1 ma-0"
                      :class="{ 'layer-treeview-layer-checkbox': !item.selectableDisabled }"
                      :on-icon="
                        !item.layers || item.layers.every(layer => layer.visible || !layer['has_permission'])
                          ? '$checkboxOn'
                          : 'mdi-minus-box'
                      "
                    />
                  </span>
                </template>
                {{ item.selectableTooltip }}
              </v-tooltip>
            </v-col>
            <v-col cols="auto" class="pa-0" v-if="!item.styles">
              <v-row no-gutters align="center">
                <v-col cols="auto" class="pa-0" v-if="!item.layers">
                  <layer-style-preview-icon
                    is-icon
                    :layer-type="getLayerType(item)"
                    :layer-style="item.style"
                    :layer-id="item.id"
                    has-color-picker
                  />
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="auto" class="pa-0 ml-1 flex-shrink-1 overflow-hidden">
              <v-row no-gutters align="center">
                <v-col
                  cols="auto"
                  class="pa-0 text-body-2 text-truncate"
                  :class="{
                    'text--disabled': !item.has_permission && !item.layers,
                    'font-weight-bold': item.layers ? true : false,
                  }"
                >
                  <v-tooltip bottom color="font" :disabled="Boolean(item.layers)">
                    <template #activator="{ on }">
                      <span v-on="on" :class="{ 'text--disabled': getIsLayerHiddenByZoom(item) }">
                        {{ item.name }}
                      </span>
                      <span v-on="on" class="text--disabled font-italic" v-if="item.cache">
                        {{ '(cache)' }}
                      </span>
                    </template>
                    {{ getLayerTooltip(item) }}
                  </v-tooltip>
                </v-col>
              </v-row>
            </v-col>
            <v-col v-if="item.description" cols="auto" class="pa-0 ml-2">
              <v-row no-gutters align="center">
                <v-col class="d-flex items-center">
                  <v-tooltip bottom max-width="500" content-class="tooltip_multiline" color="font">
                    <template #activator="{ on }">
                      <v-icon v-on="on" small color="primary"> mdi-help-circle </v-icon>
                    </template>
                    <span>{{ item.description }}</span>
                  </v-tooltip>
                </v-col>
              </v-row>
            </v-col>
            <v-spacer />
            <v-col cols="auto" class="pa-0" :class="{ 'no-print': isQuickPrint }">
              <v-row no-gutters align="center">
                <template v-if="item.isActionsVisible">
                  <v-col cols="auto" v-for="(button, idx) of item.buttons" :key="idx">
                    <assign-parcel-trigger
                      v-if="button.name === 'assignParcel'"
                      @customAction="openAssignParcelDialog"
                      :layer="item.id"
                      :datasource="item.data_source_name"
                      forced-provider="uldk"
                      geometry-type="polygon"
                    >
                      <template #default="{ item: itemButton, defaultAction }">
                        <v-tooltip v-if="uldkToolsAttributesMappings[item.data_source_name]" bottom color="font">
                          <template #activator="{ on: onTooltip }">
                            <v-btn
                              small
                              icon
                              v-on="onTooltip"
                              :disabled="itemButton.disabled"
                              :loading="itemButton.loading"
                              @click.native.stop="defaultAction"
                            >
                              <v-icon
                                v-bind="{
                                  color: itemButton.isActive ? 'link' : $_colors.iconIdle,
                                  style: 'font-size: 19px',
                                  icon: itemButton.icon,
                                }"
                              >
                                {{ itemButton.icon }}
                              </v-icon>
                            </v-btn>
                          </template>
                          {{ itemButton.tooltip }}
                        </v-tooltip>
                      </template>
                    </assign-parcel-trigger>
                    <v-tooltip v-else bottom color="font">
                      <template #activator="{ on: onTooltip }">
                        <v-btn
                          small
                          icon
                          v-on="onTooltip"
                          @click.native.stop="buttonAction(button.name, item, button.action)"
                        >
                          <v-icon v-bind="button.icon"> {{ button.icon.icon }} </v-icon>
                        </v-btn>
                      </template>
                      {{ button.tooltip }}
                    </v-tooltip>
                  </v-col>
                </template>
                <v-col cols="auto">
                  <dots-menu
                    v-if="item.hasDotsMenus"
                    :items="item.dotsMenus"
                    @delete="$emit('action', { name: 'showLayerDeleteDialog', value: item })"
                    @changeStyle="$emit('action', { name: 'openLayerStyle', value: item })"
                    @refresh="$emit('action', { name: 'refreshProjectLayer', value: item.layer_id })"
                    @changeGroupName="$emit('action', { name: 'showChangeGroupNameDialog', value: item })"
                    @deleteGroup="$emit('action', { name: 'showGroupDeleteDialog', value: item })"
                    :close-on-content-click="false"
                  >
                    <template #transparency>
                      <v-row no-gutters align="center" justify="center" style="margin-top: -10px; height: 30px">
                        <v-col cols="10">
                          <v-slider
                            @change="
                              $emit('action', {
                                name: 'setLayerOpacity',
                                value: { layerId: item.layer_id, value: $event },
                              })
                            "
                            :value="item.opacity"
                            step="0.01"
                            min="0"
                            max="1"
                            hide-details
                          ></v-slider>
                        </v-col>
                      </v-row>
                    </template>
                  </dots-menu>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <v-row v-if="item.styles && item?.open" style="padding-left: 29px; width: 100%" class="mt-0">
            <v-col class="pa-0 pl-3 pb-2">
              <legend-layers-list-styles
                :default-style="item.style"
                :styles="item.styles"
                :layer-id="item.id"
                :layer-type="item.layerType"
                :has-permission="item.has_permission"
                :layer-visible="item.visible"
                @hasUnsavedChanges="$emit('hasUnsavedChanges', $event)"
              />
            </v-col>
          </v-row>
        </v-col>
      </template>
      <template #[`empty-group-inner`]="{ emptyGroupTranslationPath }">
        <v-row no-gutters style="padding-left: 24px">
          {{ $i18n.t(emptyGroupTranslationPath) }}
        </v-row>
      </template>
    </treeview-draggable>
    <add-edit-feature-dialog
      v-if="isAddFeatureDialogLoaded"
      :is-visible.sync="isAddFeatureDialogVisible"
      :title="$i18n.t('dialog.addFeatureParcel')"
      v-bind="addFeatureDialogData"
      @done="onFeatureAdded"
    />
  </span>
</template>
<script>
import { get } from 'vuex-pathify';
import DotsMenu from '@/components/DotsMenu';
import LayerStylePreviewIcon from '@/components/LayerStylePreviewIcon';
import LegendLayersListStyles from '@/components/LegendLayersListStyles';
import TreeviewDraggable from '@/components/TreeviewDraggable';
import stylePreviewUtilities from '@/mixins/stylePreviewUtilities';
import AssignParcelTrigger from '@/components/AssignParcelTrigger';
import AddEditFeatureDialog from '@/components/AddEditFeatureDialog';
export default {
  name: 'ProjectLayersTreeview',
  components: {
    DotsMenu,
    LayerStylePreviewIcon,
    LegendLayersListStyles,
    TreeviewDraggable,
    AssignParcelTrigger,
    AddEditFeatureDialog,
  },
  mixins: [stylePreviewUtilities],
  props: {
    value: {
      type: Array,
      default: () => {
        return [];
      },
    },
    isManipulationDisabled: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    isAddFeatureDialogLoaded: false,
    isAddFeatureDialogVisible: false,
    addFeatureDialogData: {},
  }),
  computed: {
    isUldkToolsEnabled: get('admin/settingsValues@uldk_tools_module_enabled'),
    uldkToolsMetadata: get('admin/additionalModules@ULDK_TOOLS_MODULE'),
    isUldkToolsModuleConfigured() {
      return this.uldkToolsMetadata?.enabled && this.uldkToolsMetadata?.configured && this.isUldkToolsEnabled;
    },
    mapPanelDataFetched: get('map/mapPanelDataFetched'),
    isModifySnappingActive: get('edit/isModifySnappingActive'),
    isQuickPrint: get('tools/toolStatus@isQuickPrintToolActive'),
    currentProjectId: get('layers/project.id'),
    uldkToolsAttributesMappings: get('search/uldkToolsAttributesMappings'),
    modulesPermissions: get('users/toolsPermissions'),
    layersPermissions: get('users/layersPermissions'),
    isDirectionArrowsModuleEnabled: get('admin/additionalModules@DIRECTION_ARROWS_VISIBLE.enabled'),
    isDirectionArrowsVisible() {
      return this.isDirectionArrowsModuleEnabled && this.modulesPermissions['DIRECTION_ARROWS_VISIBLE'].main_value > 0;
    },
    currentLayerId() {
      return this.$route.params.lid;
    },
    computedLayers: {
      get() {
        const storageOpenElements =
          JSON.parse(localStorage.getItem('projects-open-elements') || '{}')[+this.currentProjectId] || [];
        let uniqueTreeviewKey = 0;
        return JSON.parse(JSON.stringify(this.value)).map(element => {
          if (element.layers) {
            element.layers = element.layers.map(layer => {
              uniqueTreeviewKey++;
              return this.getLegendLayer(layer, uniqueTreeviewKey, storageOpenElements);
            });
            uniqueTreeviewKey++;
            return this.getLegendGroup(element, uniqueTreeviewKey, storageOpenElements);
          } else {
            uniqueTreeviewKey++;
            return this.getLegendLayer(element, uniqueTreeviewKey, storageOpenElements);
          }
        });
      },
      set(nV) {
        const openElementsId = nV.reduce((acc, item) => {
          if (item.open) acc.push(item.layers ? `group-${item.id}` : `layer-${item.id}`);
          if (item.layers) item.layers.filter(layer => layer.open).forEach(layer => acc.push(`layer-${layer.id}`));
          return acc;
        }, []);
        const storageOpenElements = JSON.parse(localStorage.getItem('projects-open-elements') || '{}');
        storageOpenElements[+this.currentProjectId] = openElementsId;
        localStorage.setItem('projects-open-elements', JSON.stringify(storageOpenElements));
        this.$emit(
          'input',
          JSON.parse(JSON.stringify(nV)).map(element => {
            if (element.layers) {
              element.layers = element.layers.map(layer => {
                return this.setLegendLayer(layer);
              });
              return this.setLegendGroup(element);
            } else {
              return this.setLegendLayer(element);
            }
          })
        );
      },
    },
  },
  watch: {
    isAddFeatureDialogVisible(nV) {
      if (!nV) this.$root.$emit('deleteSidebarGeometry');
    },
  },
  methods: {
    getGroupValue(item) {
      if (item.layers.find(layer => layer.visible)) return true;
      return false;
    },
    openAssignParcelDialog({ values, geometry, layerId, datasource }) {
      this.addFeatureDialogData = {
        featureData: {
          layer: layerId,
          datasource,
        },
        featureMetadata: {
          properties: values,
        },
        geometry,
      };
      this.isAddFeatureDialogLoaded = this.isAddFeatureDialogVisible = true;
    },
    onFeatureAdded(closeDialog, featureData) {
      this.$root.$emit('refreshTableData');
      this.$root.$emit('refreshMvtSource', featureData.layer);
      this.$root.$emit('updateLegendStylesCounts', { layersIds: [featureData.layer] });
      this.$root.$emit('refreshFormData');
      closeDialog();
    },
    getGroupIcon(item) {
      if (!item.layers.length) return 'mdi-checkbox-blank-outline';
      else if (item.layers.every(layer => layer.visible || !layer['has_permission'])) return 'mdi-checkbox-marked';
      else if (item.layers.find(layer => layer.visible)) return 'mdi-minus-box';
      else return 'mdi-checkbox-blank-outline';
    },
    getLegendGroup(group, uniqueTreeviewKey, openElementsId = []) {
      const selectableDisabled =
        !group.layers.find(layer => layer.has_permission) || this.isModifySnappingActive || group.layers.length < 1;
      return {
        ...group,
        uniqueTreeviewKey,
        open: openElementsId.includes(`group-${group.id}`) || !group.layers.length,
        isActionsVisible: group.layers.find(layer => layer.type === 'features_layer' && layer.has_permission),
        dotsMenus: this.isManipulationDisabled
          ? []
          : [
              {
                name: 'changeGroupName',
              },
              {
                name: 'deleteGroup',
              },
            ],
        hasDotsMenus: !this.isManipulationDisabled,
        selectableTooltip: this.isModifySnappingActive
          ? this.$i18n.t('button.toChangeGroupVisibility', { reason: this.$i18n.t('button.turnOffEditing') })
          : group.layers.length > 0
            ? group.layers.filter(layer => layer.has_permission).length > 0
              ? this.$i18n.t('button.groupVisbility', {
                  onOff: this.$i18n.t(
                    `button.${
                      group.layers.filter(layer => layer.has_permission).every(layer => layer.visible) ? 'off' : 'on'
                    }`
                  ),
                })
              : this.$i18n.t('button.changeGroupVisibilityNoPermission')
            : this.$i18n.t('button.changeGroupVisibilityNoLayers'),
        selectableDisabled,
        buttons: group.layers.find(layer => layer.type === 'features_layer' && layer.has_permission)
          ? [
              {
                name: 'changeLabelsVisibility',
                icon: {
                  color: this.$_colors.iconIdle,
                  style: 'height: 21px; width: 21px',
                  icon: group.layers
                    .filter(layer => layer.type === 'features_layer' && layer.has_permission)
                    .every(layer => layer.labels_visible)
                    ? '$labelActive'
                    : '$label',
                },
                tooltip: group.layers
                  .filter(layer => layer.type === 'features_layer' && layer.has_permission)
                  .every(layer => layer.labels_visible)
                  ? this.$i18n.t('button.turnOffLabels')
                  : this.$i18n.t('button.turnOnLabels'),
              },
            ]
          : [],
      };
    },
    getLegendLayer(layer, uniqueTreeviewKey, openElementsId = []) {
      const hasLayerStyles = !!layer.style?.ranges || !!layer.style?.uniques;
      const dotsMenus = this.getDotsMenuItems(
        layer.type,
        layer.geometry_type,
        layer.supports_mvt,
        layer.has_permission,
        this.isManipulationDisabled
      );
      return {
        ...layer,
        open: hasLayerStyles ? openElementsId.includes(`layer-${layer.id}`) : undefined,
        uniqueTreeviewKey,
        layerType: this.getLayerType(layer),
        styles: this.getLayerStylesList(layer),
        visible: this.getLayerVisible(layer),
        isActionsVisible: layer.type === 'features_layer' && layer.has_permission,
        dotsMenus,
        hasDotsMenus: dotsMenus?.length > 0,
        selectableDisabled: !layer.has_permission || layer.geometry_type === null || this.isModifySnappingActive,
        selectableTooltip: this.getLayerChangeVisibilityTooltip(
          layer.visible,
          layer.geometry_type,
          layer.has_permission,
          this.isModifySnappingActive
        ),
        buttons:
          layer.type === 'features_layer' && layer.has_permission
            ? [
                ...(layer.geometry_type
                  ? [
                      ...(this.isUldkToolsModuleConfigured &&
                      (!Object.keys(this.uldkToolsAttributesMappings)?.length ||
                        this.uldkToolsAttributesMappings[layer.data_source_name]) &&
                      ['polygon', 'multipolygon'].includes(layer.geometry_type) &&
                      this.layersPermissions[layer.id]?.main_value > 1
                        ? [
                            {
                              name: 'assignParcel',
                            },
                          ]
                        : []),
                      {
                        name: 'changeLabelsVisibility',
                        icon: {
                          color: this.$_colors.iconIdle,
                          style: 'height: 21px; width: 21px',
                          icon: layer.labels_visible ? '$labelActive' : '$label',
                        },
                        tooltip: layer.labels_visible
                          ? this.$i18n.t('button.turnOffLabels')
                          : this.$i18n.t('button.turnOnLabels'),
                      },
                      ...(this.isDirectionArrowsVisible &&
                      (layer.geometry_type === 'linestring' || layer.geometry_type === 'multilinestring')
                        ? [
                            {
                              name: 'changeLineArrowsVisibility',
                              icon: {
                                color: layer.direction_arrows_visible ? 'link' : this.$_colors.iconIdle,
                                style: 'font-size: 1.3rem',
                                icon: 'mdi-axis-arrow',
                              },
                              tooltip: layer.direction_arrows_visible
                                ? this.$i18n.t('button.turnOffDirectionArrows')
                                : this.$i18n.t('button.turnOnDirectionArrows'),
                            },
                          ]
                        : []),
                      {
                        name: 'zoomToLayer',
                        icon: { color: this.$_colors.iconIdle, style: 'height: auto', icon: 'mdi-fullscreen' },
                        tooltip: this.$i18n.t('button.zoomToLayer'),
                      },
                    ]
                  : []),
                {
                  name: 'setLayer',
                  icon: {
                    color: this.currentLayerId == layer.layer_id ? 'link' : this.$_colors.iconIdle,
                    style: 'height: auto',
                    icon: 'mdi-table-large',
                  },
                  tooltip:
                    this.currentLayerId == layer.layer_id
                      ? this.$i18n.t('button.closeTable')
                      : this.$i18n.t('button.openTable'),
                },
              ]
            : [],
      };
    },
    setLegendGroup(group) {
      const list = [
        'isActionsVisible',
        'dotsMenus',
        'hasDotsMenus',
        'buttons',
        'selectableDisabled',
        'selectableTooltip',
        'uniqueTreeviewKey',
        'open',
      ];
      for (const l of list) {
        delete group[l];
      }
      return group;
    },
    setLegendLayer(layer) {
      const list = [
        'iconSrc',
        'color',
        'layerType',
        'styles',
        'isActionsVisible',
        'dotsMenus',
        'hasDotsMenus',
        'isExpaned',
        'selectableDisabled',
        'selectableTooltip',
        'buttons',
        'uniqueTreeviewKey',
      ];
      for (const l of list) {
        delete layer[l];
      }
      return layer;
    },
    getDotsMenuItems(type, geomType, supportsMvt, hasPermission, isManipulationDisabled) {
      return [
        ...(hasPermission && geomType !== null
          ? [
              {
                name: 'transparency',
              },
            ]
          : []),
        ...(hasPermission &&
        (type === 'service_layer' || (type === 'features_layer' && geomType)) &&
        !isManipulationDisabled
          ? [
              {
                name: 'changeStyle',
                disabled: !this.mapPanelDataFetched,
                tooltip: this.mapPanelDataFetched ? '' : this.$i18n.t('default.downloadingDataInProgress'),
              },
            ]
          : []),
        ...(hasPermission && type === 'features_layer' && supportsMvt
          ? [
              {
                name: 'refresh',
              },
            ]
          : []),
        ...(!isManipulationDisabled
          ? [
              {
                name: 'delete',
              },
            ]
          : []),
      ];
    },
    selectElement(uid, value) {
      let parentItemIdx = undefined;
      const items = JSON.parse(JSON.stringify(this.computedLayers));
      let item = items.find(item => item.uniqueTreeviewKey === uid);
      const type = item?.layers ? 'group' : 'layer';
      let itemIdx = items.findIndex(item => item.uniqueTreeviewKey === uid);
      if (itemIdx < 0) {
        for (const group of items) {
          if (group.layers) {
            itemIdx = group.layers.findIndex(layers => layers.uniqueTreeviewKey === uid);
            if (itemIdx >= 0) {
              parentItemIdx = items.findIndex(item => item.uniqueTreeviewKey === group.uniqueTreeviewKey);
              item = group.layers.find(group => group.uniqueTreeviewKey === uid);
              break;
            }
          }
        }
      }
      item.visible = value;
      if (parentItemIdx || parentItemIdx === 0) {
        items[parentItemIdx].layers[itemIdx] = item;
        const parentItemValue = items[parentItemIdx].layers.find(layers => layers.visible) ? true : false;
        items[parentItemIdx].visible = parentItemValue;
      } else {
        items[itemIdx] = item;
        if (type === 'group') {
          items[itemIdx].layers = items[itemIdx].layers.map(layers => {
            return { ...layers, visible: value };
          });
        }
      }
      this.$emit('action', { name: 'changeLayerVisibility', value: { type, itemIdx, parentItemIdx, value } });
      this.computedLayers = items;
    },
    buttonAction(name, item, customAction) {
      this.$emit('action', { name, value: { item, type: item.layers ? 'group' : 'layer', customAction } });
    },
  },
};
</script>
<style lang="scss" scoped>
::v-deep {
  .v-treeview-node__root:hover {
    background-color: #f6f6f6;
  }
  .v-treeview-node__root {
    min-height: 32px !important;
  }
  .v-input--selection-controls__input {
    margin-right: 0 !important;
  }
  .layer-treeview-layer-checkbox:hover::before {
    content: '';
    border-radius: 50%;
    position: absolute;
    width: 24px;
    height: 24px;
    scale: 1.2;
    transition: none;
    background-color: #cacaca !important;
  }
  .v-input--selection-controls__input:hover .v-input--selection-controls__ripple::before {
    background: unset !important;
  }
}
</style>
