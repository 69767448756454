<template>
  <data-input-select ref="data-input-v-select" v-bind="inputBind" v-model="computedValue">
    <template v-for="(value, key) in $scopedSlots" #[key]="scope">
      <slot :name="key" v-bind="scope" />
    </template>
  </data-input-select>
</template>
<script>
import DataInputSelect from '@/components/DataInputSelect';
export default {
  name: 'DataInputUser',
  components: { DataInputSelect },
  props: {
    value: {
      default: null,
    },
  },
  data: () => ({
    oldValueProxy: null,
  }),
  computed: {
    deleteUserSufix() {
      return `(${this.$store.get('admin/modulesMapping@users.deleted_suffix')})`;
    },
    computedValue: {
      get() {
        return this.value;
      },
      set(nV) {
        this.$emit('input', nV);
      },
    },
    filteredItems() {
      return this.$attrs.items.filter(
        item =>
          !item.text.includes(this.deleteUserSufix) ||
          item.value === this.oldValueProxy ||
          item.value === this.computedValue
      );
    },
    inputBind() {
      return {
        ...this.$props,
        ...this.$attrs,
        items: this.filteredItems,
      };
    },
  },
  mounted() {
    if (this.computedValue) this.oldValueProxy = this.computedValue;
  },
};
</script>
